@font-face {
	font-family: "Clash Display";
	src: url("./fonts/ClashDisplay-Medium.woff");
}
@font-face {
	font-family: "SF Pro";
	src: url("./fonts/SF-Pro-Display-Light.otf");
}

@font-face {
	font-family: "SF Pro-regular";
	src: url("./fonts/SF-Pro-Display-Regular.otf");
}

html,
body {
	height: 100%;
}

:root {
	--text_white: #fff;
	--text_dark: #3d3e3f;
	--text-success: #228403;
	--btn_color: #fff;
	--btn_bg_color: #fff;
	--btn_hover_color: #fff;
	--hover_bg_color: rgba(127, 127, 127, 0.5);
	--border_color: #8650ff;
	--border_sec_color: #fff;
	--shadow_color: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	--background: #8651ff;
	--background_sec: #121212;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "Clash Display";
	color: var(--text_white);
	margin: 0px;
	padding: 0px;
}

p,
span,
a {
	font-family: "SF Pro";
	color: var(--text_white);
	margin: 0px;
	padding: 0px;
}
a:hover {
	color: inherit;
}

ul,
li {
	list-style: none;
}
a {
	text-decoration: none;
}

button {
	border: unset;
}

input,
textarea,
select {
	border-radius: 8px;
	border: 1px solid var(--border_sec_color);
	background: var(--background_sec);
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	box-sizing: border-box;
	padding: 8px;
	font-family: "SF Pro";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%;
	letter-spacing: 0.2px;
	display: block;
	width: 100%;
	color: var(--text_white);
	height: 40px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px black inset !important;
	-webkit-text-fill-color: #fff !important;
}

input[type="checkbox"] {
	width: auto;
}

label {
	color: var(--text_white);
	font-family: "Clash Display";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%;
	letter-spacing: 0.2px;
	margin-bottom: 8px;
}

label span {
	color: rgba(255, 255, 255, 0.5);
	font-family: "Clash Display";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%;
	letter-spacing: 0.2px;
}
img {
	max-width: 100%;
}
body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	background-color: #08090b;
}

/* Reuseable css start */

.global_scroll::-webkit-scrollbar {
	width: 5px;

	cursor: pointer;
}

.global_scroll::-webkit-scrollbar-track {
	box-shadow: inset 10px 10px 10px #fff;
	cursor: pointer;
}

.global_scroll::-webkit-scrollbar-thumb {
	border: 4px solid transparent;
	background-color: var(--background);
	cursor: pointer;
}

.flex {
	display: flex;
}
.flex-grow {
	flex-grow: 1;
}

.text-center {
	text-align: center;
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.mt10 {
	margin-top: 10px;
}
.mb10 {
	margin-bottom: 10px;
}

.ml10 {
	margin-left: 10px;
}

.mr10 {
	margin-right: 10px;
}

.pt10 {
	padding-top: 10px;
}
.pb10 {
	padding-bottom: 10px;
}

.pl10 {
	padding-left: 10px;
}

.pr10 {
	padding: 10px;
}

.mt8 {
	margin-top: 8px;
}
.mb8 {
	margin-bottom: 8px;
}

.mr8 {
	margin-right: 8px;
}

.mt16 {
	margin-top: 16px;
}
.mb16 {
	margin-bottom: 16px;
}

.mt32 {
	margin-top: 32px;
}
.mb32 {
	margin-bottom: 32px;
}

.mauto {
	margin-top: auto;
	margin-bottom: auto;
}

.border {
	border: 1px solid var(--border_color);
}

button.add_more {
	background: linear-gradient(97deg, #d0a10a 0%, #ff6750 65.85%);
}

.flex {
	display: flex;
}

.button {
	padding: 10px 12px;
	background-color: var(--btn_bg_color);
	color: var(--btn_color);
}

.gradient_button {
	padding: 12px;
	background: linear-gradient(97deg, #f9c3ff 0%, #8650ff 65.85%);
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	border-radius: 8px;
	border: 1px solid #2d333e;
	color: var(--btn_color);
	font-family: "Clash Display";
	font-size: 14px;
	font-style: normal;
	line-height: 120%;
	letter-spacing: 0.2px;
}

.gradient_button.small {
	padding: 8px;
}
.notfound {
	font-size: 36px;
}

/* Reuseable css end */

/* Header css start */

.header {
	padding-top: 30px;
	padding-bottom: 30px;
}
.header .left {
	margin-top: auto;
	margin-bottom: auto;
	flex-grow: 1;
}
.header .moddle {
	margin-top: auto;
	margin-bottom: auto;
	flex-grow: 1;
}
.header .right {
	margin-top: auto;
	margin-bottom: auto;
	flex-grow: 1;
}

.header .moddle .menu {
	padding: 8px;
	border-radius: 8px;
	border: 1px solid rgba(255, 255, 255, 0.5);
	background: radial-gradient(63.94% 63.94% at 50% 0%, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%), rgba(255, 255, 255, 0.01);
	width: max-content;
}

.header .moddle .menu_item {
	position: relative;
}
.header .moddle .menu_item:hover .sub_menu_item {
	display: block !important;
}

.header .moddle .menu .sub_menu_item {
	margin-top: -14px;
	width: 200px;
	position: absolute;
	top: 50px;
	left: 0px;
	padding: 8px 8px 8px 0px;
	border-left: 5px solid red;
	background-color: var(--background_sec);
	border: 1px solid var(--border_sec_color);
	border-radius: 0px 8px 8px 0px;
	z-index: 4;
}

.header .moddle .menu .sub_menu_item .submenu_border {
	position: absolute;
	height: 100%;
	width: 10px;
	top: 0px;
	background: linear-gradient(97deg, #f9c3ff 0%, #8650ff 65.85%);
	border-radius: 10px 0px 0px 10px;
	left: -10px;
}

.header .moddle .menu .sub_menu_item a {
	display: block;
}

.header .moddle a,
.header .moddle .not_link {
	color: #fff;
	cursor: pointer;
	margin-right: 10px;
	font-family: "SF Pro-regular";
	font-size: 16px;
	line-height: 24px;
	text-align: left;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;
	padding: 6px 10px;
	display: inline-block;
	border-radius: 8px;
	opacity: 70%;
}

.header .moddle a:hover,
.header .moddle .not_link:hover {
	background-color: rgba(127, 127, 127, 0.5);
	color: var(--btn_hover_color);
	opacity: unset;
}

.header .right {
	margin-top: auto;
	margin-bottom: auto;
}

.header .right .user_item a.signup {
	font-family: "Clash Display";
	font-size: 16px;
	font-style: normal;
	line-height: 120%;
	border: 1px solid #fff;
	border-radius: 8px;
	padding: 12px;
	color: var(--text_dark);
	background-color: var(--text_white);
	font-size: 16px;
}

.header .right .user_item a.signup:hover {
	background-color: var(--hover_bg_color);
	color: var(--btn_hover_color);
}

.login_user_pro {
	position: relative;
	cursor: pointer;
}
.login_user_pro svg {
	color: #fff;
}
.login_user_pro .profile img {
	width: 40px;
	height: 40px;
	border-radius: 50%;
}

.login_user_pro .login_text {
	display: flex;
	justify-content: end;
}

.login_user_pro .login_text .angle {
	margin-top: auto;
	margin-bottom: auto;
	margin-left: 8px;
}
.login_user_pro .login_text .profile {
	margin-top: auto;
	margin-bottom: auto;
	margin-right: 7px;
	margin-left: 7px;
	width: 40px;
	height: 40px;
}

.login_user_pro .login_text .name {
	margin-top: auto;
	margin-bottom: auto;
}

.login_user_pro .login_text .name span {
	color: #fff;
	font-size: 13.737px;
	font-style: normal;
	font-weight: 700;
	line-height: 18.316px;
	font-family: "SF Pro-regular";
}

.login_user_pro .user_about {
	padding-bottom: 16px;
}

.login_user_pro_box .menu_list {
	padding-top: 16px;
	border-top: 1px solid #3d3e3f;
}

.login_user_pro_box {
	position: absolute;
	border-radius: 8px;
	border: 1px solid #3d3e3f;
	background: #0f1012;
	padding-top: 16px;
	padding-bottom: 16px;
	width: 278px;
	top: 50px;
	right: 0px;
	z-index: 1;
}

.login_user_pro_box .user_about,
.login_user_pro_box .menu_list {
	padding-left: 12px;
	padding-right: 12px;
}

.login_user_pro_box .name {
	color: #fff;
	font-family: "SF Pro";
	font-size: 16px;
	font-style: normal;
	font-weight: 510;
	line-height: 150%;
}
.login_user_pro_box .email {
	color: #1fff71;
	font-family: "SF Pro";
	font-size: 14px;
	font-style: normal;
	font-weight: 510;
	line-height: 150%;
	margin-top: 4px;
}

.login_user_pro_box .menu_list .menu_item {
	padding-bottom: 16px;
}

.login_user_pro_box .menu_list .menu_item a,
.login_user_pro_box .menu_list .menu_item .not_link {
	display: block;
}

.login_user_pro_box .menu_list .menu_item svg {
	margin-right: 8px;
}

.login_user_pro_box_wrap {
	padding-top: 8px;
}

.login_user_pro_box {
	display: none;
	margin-top: 0px;
	top: unset;
}
.login_user_pro:hover .login_user_pro_box {
	display: block;
}
.header.mobile {
	display: none;
}

@media screen and (max-width: 768px) {
	.header {
		display: none;
	}

	.header.mobile {
		display: flex;
	}
	.header.mobile .menu {
		width: 200px;
		position: absolute;
		right: 0px;
		top: 35px;
		border-radius: 8px;
		border: 1px solid var(--border_sec_color);
		background: #000;
		z-index: 2;
	}

	.header.mobile .right .menu_item a,
	.header.mobile .right .menu_item span {
		font-family: "SF Pro-regular";
		font-size: 16px;
		line-height: 24px;
		text-align: left;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
		padding: 6px 10px;
		display: inline-block;
		border-radius: 8px;
		opacity: 70%;
		display: block;
	}
	.header.mobile .right .menu_item a:hover {
		background-color: rgba(127, 127, 127, 0.5);
		color: var(--btn_hover_color);
		opacity: unset;
	}

	.header.mobile .left {
		margin-top: unset;
		margin-bottom: unset;
	}

	.header.mobile .right {
		text-align: right;
	}

	.header.mobile .menu_icon {
		position: relative;
	}
	.header.mobile .menu_icon svg {
		cursor: pointer;
	}
}

/* Header css end */

/* Footer css start */
/* .footer_container {
	height: 100%;
	display: flex;
	flex-direction: column;
	margin-top: auto;
}
#wrapper {
	height: 100vh;
} */

#root {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

footer {
	margin-top: auto;
	display: block;
}

.footer_row {
	margin-top: auto;
	padding-bottom: 32px;
}

.footer_row .left {
	margin-top: auto;
	margin-bottom: auto;
}

.footer_row .right {
	text-align: right;
	margin-top: auto;
	margin-bottom: auto;
}

.footer_row .right .title {
	color: #fff;
	font-family: "Clash Display";
	font-size: 20px;
	font-style: normal;
	line-height: 150%;
	text-align: left;
	margin-bottom: 16px;
}

.footer_row .subscribe_form input,
.footer_row .subscribe_form input:focus {
	border-radius: 4px;
	border: 1px solid var(--border_color);
	background: #1d1c20;
	width: 461px;
	height: 36px;
	outline: none;
	color: #fff;
	padding: 3px 6px;
	margin-right: 5px;
}

.footer_row .subscribe_form button {
	color: #141414;
	text-align: center;
	font-family: "Clash Display";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 19.5px;
	letter-spacing: 0.64px;
	border-radius: 4px;
	border: 1px solid #272729;
	background: #fff;
	outline: none;
	padding: 4px 12px;
}
.footer_row .subscribe_form button:hover {
	background: var(--background);
	color: #fff;
}
.copyright_row {
	color: #fff;
	font-family: "SF Pro";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}

.copyright_row .right {
	padding-bottom: 32px;
}

.copyright_row .right {
	text-align: right;
}

.copyright_row .icon_wrapper svg {
	margin-right: 20px;
}
.copyright_row .icon_wrapper svg:last-child {
	margin-right: 0px;
}

@media screen and (max-width: 767px) {
	.footer_row {
		display: block;
	}
	.footer_row .left img {
		margin-bottom: 16px;
	}
}
@media screen and (max-width: 576px) {
	.copyright_row .left {
		width: max-content;
		max-width: max-content;
	}
	.copyright_row .icon_wrapper svg {
		margin-right: 8px;
	}
}
/* Footer css end */

/* Slider css start */

.slider {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

.slider .slider_overlay {
	background: rgba(0, 0, 0, 0.4);
	display: flex;
}
.slider .slider_overlay > div {
	width: 60%;
	padding: 16px;
}

.slider .content_box h2 {
	font-family: "Clash Display";
	font-size: 56px;
	font-style: normal;
	line-height: 120%;
}
.slider .content_box p {
	font-family: "SF Pro";
	font-size: 18px;
	font-style: normal;
	line-height: 150%;
}
.slider .content_box .add_review_btn {
	border-radius: 8px;
	border: 1px solid var(--Gradient-Primary, #f9c3ff);
	padding: 12px;
	margin-top: 16px;
	display: inline-block;
	color: #fff;
	font-family: "Clash Display";
	font-size: 16px;
	font-style: normal;
	line-height: 120%;
}

.slider .content_box .add_review_btn:hover {
	background-color: var(--hover_bg_color);
}

.circle_box {
	display: flex;
}
.circle_box .circle_left {
	margin-right: 10px;
}
.circle_box .circle_right {
	width: 100%;
	margin-top: auto;
	margin-bottom: auto;
}

.circle_progress .CircularProgressbar .CircularProgressbar-text {
	fill: #fff;
	font-family: "Clash Display";
	font-size: 32px;
	font-style: normal;
	line-height: 120%; /* 86.731px */
	letter-spacing: 0.226px;
}

.progress-bar-container {
	width: 100%;
	background-color: #fff;
	border-radius: 5px;
	box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
}

.progress-bar {
	height: 8px;
	width: 80%;
	background: linear-gradient(97deg, #f9c3ff 0%, #8650ff 65.85%);
	border-radius: 5px;
	text-align: center;
	line-height: 30px;
	font-weight: bold;
}

.progress_box {
	margin-bottom: 16px;
}
.progress_box p {
	font-family: "Clash Display";
	font-size: 14px;
	font-style: normal;
	line-height: 120%;
	margin-bottom: 8px;
}

.content_box_2 p.description {
	margin-top: 50px;
	margin-bottom: 50px;
}

.carousel_all .splide__arrow--next {
	bottom: -78px !important;
	top: unset;
	right: 0px;
	border: 2px solid rgba(255, 255, 255, 0.5);
}
.carousel_all .splide__arrow--prev {
	bottom: -78px !important;
	top: unset;
	right: 40px;
	left: unset;
	border: 2px solid #fff;
}
.carousel_all .splide__arrow svg {
	fill: #fff;
}
.carousel_all .splide__arrow {
	background: transparent;
}

.home_slider .splide__pagination {
	display: block;
	right: 0px;
	left: unset;
	top: 0px;
}

.home_slider .splide__pagination li {
	display: block;
}
.home_slider .splide__pagination li .splide__pagination__page {
	width: 4px;
	height: 36px;
	border-radius: unset;
	background: #7e7e7e;
	opacity: 1;
}
.home_slider .splide__pagination__page.is-active {
	transform: unset;
	background: #fff !important;
}

@media screen and (max-width: 991px) {
	.slider .slider_overlay > div {
		width: 100%;
	}
}

@media screen and (max-width: 767px) {
	.slider .content_box h2 {
		color: #fff;
		font-family: "Clash Display";
		font-size: 24px;
		font-style: normal;
		font-weight: 500;
		line-height: 120%;
		letter-spacing: 0.2px;
		margin-bottom: 8px;
	}
	.slider .content_box p {
		font-family: "SF Pro";
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 120%;
		letter-spacing: 0.2px;
	}
}
@media screen and (max-width: 767px) {
	.slider .left_slide {
		width: 100%;
	}
}

/* Slide css end */

/* Search bar css start */
.search_bar {
	margin-top: 32px;
	margin-bottom: 32px;
	position: relative;
}
.input_left {
	border-radius: 8px;
	border: 1px solid var(--border_color);
	background: #121212;
	box-shadow: var(--shadow_color);
	width: 100%;
	border-radius: 8px;
	margin-right: 10px;
}

.search_bar .input_left .left > input {
	outline: unset;
	border: unset;
}

.search_bar .input_left > input {
	width: 100%;
	border: unset;
	outline: unset;
	height: 100%;
	box-sizing: border-box;
	padding: 10px;
	border-radius: 8px 0px 0px 8px;
	background: #121212;
	color: #fff;
}

.input_left .left {
	width: 100%;
}
.input_left > .right {
	width: 60px;
}

.input_left .right .right_filter {
	padding: 4px 0px;
	border-radius: 4px;
	border: 1px solid #3d3e3f;
	background: #0f1012;
	text-align: center;
	margin-top: 4px;
	margin-right: 5px;
	cursor: pointer;
}
.input_left .right .right_filter img {
	margin-right: 5px;
	vertical-align: text-bottom;
}

.search_pop_wrapper {
	border-radius: 16px;
	border: 1px solid var(--border_color);
	background: #121212;
	padding: 8px;
	margin-bottom: 50px;
	width: 100%;
	position: absolute;
	z-index: 2;
	margin-top: 8px;
}
.search_pop {
	border-radius: 16px;
	border: 1px solid #3d3e3f;
}

.search_pop .close_icon {
	position: absolute;
	right: 8px;
	cursor: pointer;
}

.search_pop .search_pop_heading input {
	border: unset;
	outline: unset;
}

.search_pop .search_pop_heading {
	padding: 16px 0px;
	border-bottom: 1px solid var(--border_sec_color);
}

.search_pop .search_pop_heading p {
	font-family: "SF Pro";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	color: #9c9c9d;
	padding-left: 16px;
	padding-right: 16px;
}

.search_filter_wrapper {
	display: flex;
	padding-left: 16px;
	padding-right: 16px;
	padding-bottom: 16px;
}

.search_pop .search_pop_filter {
	display: flex;
	padding-top: 8px;
}
.search_pop .search_pop_filter .filter_item {
	border-radius: 8px;
	border: 1px solid var(--border_sec_color);
	background: #0f1012;
	margin-right: 8px;
	padding: 8px;
	cursor: pointer;
}

.search_pop .search_pop_filter .filter_item.active {
	background: rgba(127, 127, 127, 0.5);
}

.search_pop .search_pop_filter .filter_item svg {
	margin-right: 4px;
}
.search_pop .search_pop_filter .filter_item span {
	color: rgba(255, 255, 255, 0.7);
	font-family: "SF Pro-regular";
	font-size: 14px;
	font-style: normal;
	font-weight: 510;
	line-height: 150%;
}

.search_filter_wrapper .left {
	flex-grow: 1;
}
.search_filter_wrapper .right {
	flex-grow: 1;
	margin-top: auto;
	margin-bottom: auto;
	text-align: right;
}

.search_filter_wrapper .right p {
	color: var(--text_white);
	font-family: "SF Pro";
	font-size: 14px;
	font-style: normal;
	font-weight: 510;
	line-height: 150%;
	border: 1px solid var(--border_sec_color);
	display: inline-block;
	padding: 8px;
	border-radius: 8px;
	cursor: pointer;
}

.search_pop .pop_bottom_bar {
	background: var(--background);
	padding: 4px 8px;
	border-radius: 0px 0px 12px 12px;
}
.search_pop .pop_bottom_bar .left {
	flex-grow: 1;
	margin-top: auto;
	margin-bottom: auto;
}
.search_pop .pop_bottom_bar .right {
	flex-grow: 1;
	text-align: right;
	margin-top: auto;
	margin-bottom: auto;
}
.search_pop .pop_bottom_bar p.ai_title {
	font-family: "SF Pro";
	font-size: 14px;
	font-style: normal;
	line-height: 150%;
}
.search_pop .pop_bottom_bar p.ai_btn,
.ai_input .right p {
	font-family: "Clash Display";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%;
	border-radius: 8px;
	border: 1px solid #fff;
	background: linear-gradient(97deg, #f9c3ff 0%, #8650ff 65.85%), #fff;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	padding: 8px;
	display: inline-block;
	cursor: pointer;
	min-width: 99px;
}

.game_container .search_bar .search_pop_wrapper {
	margin-top: -15px;
}
.game_container .reviews_box {
	margin-top: 16px;
	margin-bottom: 16px;
}

.pop_option_wrapper {
	padding: 12px;
}

.pop_option_main {
	border-radius: 8px;
	border: 1px solid #3d3e3f;
	background: #0f1012;
	padding: 12px;
}

.pop_heading .left {
	flex-grow: 1;
	margin-top: auto;
	margin-bottom: auto;
}
.pop_heading .right {
	flex-grow: 1;
	text-align: right;
	margin-top: auto;
	margin-bottom: auto;
}

.pop_heading .left p {
	font-family: "SF Pro-regular";
	font-size: 14px;
	font-style: normal;
	line-height: 150%;
}

.pop_heading .right button.apply {
	padding: 4px 12px;
	border-radius: 4px;
	background: #fff;
	margin-right: 8px;
	font-family: "SF Pro";
	font-size: 12px;
	font-style: normal;
	font-weight: 510;
	line-height: 150%;
	color: var(--background_sec);
	font-family: "SF Pro-regular";
}

.pop_heading .right button.clear {
	padding: 4px 12px;
	border-radius: 4px;
	border: 1px solid #fff;
	background: transparent;
	font-family: "SF Pro-regular";
	font-size: 12px;
	font-style: normal;
	font-weight: 510;
	line-height: 150%;
	color: var(--text_white);
}

.pop_slider {
	flex-wrap: wrap;
	overflow-y: scroll;
	max-height: 200px;
	padding: 0px 10px;
	margin-top: 20px;
}

.pop_slider::-webkit-scrollbar {
	width: 5px;
	border-radius: 16px;
}

.pop_slider::-webkit-scrollbar-track {
	box-shadow: inset 10px 10px 10px #eaeaea;
}

.pop_slider::-webkit-scrollbar-thumb {
	border-radius: 0px;
	background-color: var(--background);
}

.pop_slider > div {
	width: 50%;
}

.pop_slider .slider_input_main:nth-child(odd) {
	padding-right: 24px;
}

.slider_input .slider_input_main {
	flex-grow: 1;
}

.slider_input .left {
	flex-grow: 1;
}

.slider_input .right {
	flex-grow: 1;
}

.slider_input .middle {
	flex-grow: 1;
	margin-top: auto;
	margin-bottom: auto;
	margin-right: 8px;
	margin-left: 8px;
}

.slider_input .right {
	justify-content: end;
	display: flex;
}

.pop_slider input.number {
	border-radius: 4px;
	border: 1px solid rgba(255, 255, 255, 0.3);
	background: #121212;
	text-align: center;
	height: 35px;
	width: 100px;
	color: #fff;
}

.slider_input_main .slider_heading p {
	font-family: "Clash Display";
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%;
	padding-bottom: 12px;
}

.range_slider_wrapper {
	margin-top: 16px;
	margin-bottom: 16px;
}
.range_slider_wrapper .range-slider {
	height: 2px;
}
.range_slider_wrapper .range-slider .range-slider__thumb {
	width: 15px;
	height: 15px;
	background: #fff;
}
.range_slider_wrapper .range-slider {
	background: rgba(255, 255, 255, 0.3);
}
.range_slider_wrapper .range-slider .range-slider__range {
	background: var(--background);
}
.range-slider input[type="range"] {
	display: none;
}

.range_slider_wrapper input[type="checkbox"] {
	position: relative;
	cursor: pointer;
}
.range_slider_wrapper input[type="checkbox"]:before {
	content: "";
	display: block;
	position: absolute;
	width: 16px;
	height: 16px;
	top: 0;
	left: 0;
	border: 1px solid var(--border_color);
	border-radius: 3px;
	background-color: var(--background_sec);
	padding: 1px;
}

.range_slider_wrapper input[type="checkbox"]:checked::before {
	background-color: var(--background);
}

.range_slider_wrapper input[type="checkbox"]:checked::after {
	content: "";
	display: block;
	width: 5px;
	height: 10px;
	border: solid #ffffff;
	border-width: 0 2px 2px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
	position: absolute;
	top: 2px;
	left: 6px;
}

.filter_menu .filter_menu_item {
	padding-top: 8px;
	padding-bottom: 8px;
}
.filter_menu .filter_menu_item input {
	cursor: pointer;
}
.filter_menu .filter_menu_item label {
	padding-left: 12px;
	color: var(--text_white);
	cursor: pointer;
	font-family: "SF Pro";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}

.ai_input {
	display: flex;
}
.ai_input .left {
	flex-grow: 1;
	width: 100%;
}
.ai_input .right {
	flex-grow: 1;
	text-align: right;
	width: 120px;
}

.ai_input .left input {
	border-radius: 4px;
	border: 1px solid rgba(255, 255, 255, 0.3);
	background: #121212;
	height: 35px;
	width: 100px;
	color: #fff;
	width: 100%;
	padding: 3px 5px;
}

.ai_input .left input:focus {
	outline: 1px solid var(--border_color);
}
.ai_content {
	color: #fff;
	font-family: "SF Pro";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	margin-bottom: 10px;
}
.ai_image img {
	max-height: 300px;
	margin-bottom: 10px;
}

.pop_slider .checkbox_wrapper .checkbox_item {
	margin-bottom: 8px;
}
.pop_slider .checkbox_wrapper .checkbox_item input {
	width: auto;
	height: auto;
	cursor: pointer;
}
.pop_slider .checkbox_wrapper .checkbox_item label {
	padding-left: 8px;
	margin-bottom: unset;
	cursor: pointer;
}

@media screen and (max-width: 767px) {
	.slider_input_main .slider_heading p {
		font-size: 14px;
	}
}
@media screen and (max-width: 600px) {
	.pop_slider > div {
		width: 100%;
	}
	.pop_slider .slider_input_main:nth-child(odd) {
		padding-right: 0px;
	}

	.search_pop .search_pop_filter {
		flex-wrap: wrap;
	}
	.search_pop .search_pop_filter .filter_item {
		margin-bottom: 8px;
	}
	.search_filter_wrapper .right {
		margin-top: 8px;
		min-width: 86px;
	}
}

@media screen and (max-width: 450px) {
	.home_search .search_bar .input_left {
		margin-right: 4px;
	}
	.home_search .search_bar .input_right button {
		padding: 12px 4px;
	}
}

@media screen and (max-width: 400px) {
	.pop_option_wrapper .pop_option_main .pop_heading {
		display: block;
	}
	.pop_option_main {
		padding: 12px 6px;
	}
	.pop_slider {
		padding: 0px 5px;
	}
	.pop_slider input.number {
		width: 95px;
	}
	.home_search .search_bar .filter_menu {
		right: -50px;
	}

	.reviews_box .right {
		display: block;
	}
	.reviews_box .right .search_right {
		margin-top: 8px;
	}
}
@media screen and (max-width: 360px) {
	.home_search .search_bar .filter_menu {
		right: -100px;
	}
}

/* Search bar css end  */

/* Game css start */

.game_menu_row {
	margin-bottom: 30px;
}
.game_menu_row .game_menu button {
	color: #08090b;
	font-family: "SF Pro";
	font-size: 16px;
	font-style: normal;

	line-height: 150%;
	border-radius: 8px;
	border: 1px solid rgba(255, 255, 255, 0.06);
	background: #000;
	margin-right: 10px;
	padding: 8px 12px;
	color: #fff;
}
.game_menu_row .game_menu {
	text-align: right;
}
.game_menu_row .game_menu button:hover,
.game_menu_row .game_menu button.active {
	background: #fff;
	color: #000;
}

.game_row .game_wrapper {
	border-radius: 16px;
	border: 1px solid #3d3e3f;
	max-width: 350px;
	height: 100%;
	display: flex;
	flex-direction: column;
}

.circle_plus svg {
	color: #fff;
	cursor: pointer;
}
/* .circle_plus {
	width: 30px;
	height: 30px;
	text-align: center;
	border-radius: 50%;
	position: relative;
	border: 2px solid #fff;
	cursor: pointer;
}
.circle_plus span {
	line-height: 0px;
	display: block;
	font-family: "Clash Display";
	font-size: 30px;
	margin-top: 13px;
} */

.game_row.non_slide .game_col {
	margin-bottom: 32px;
}

.game_row .game_title {
	font-family: "Clash Display";
	font-size: 24px;
	font-style: normal;
	line-height: normal;
	margin-top: 15px;
	margin-bottom: 15px;
}

.game_row .game_img {
	position: relative;
	text-align: center;
}
.game_row .game_img img {
	max-width: 100%;
	border-radius: 16px 16px 0px 0px;
}

.game_title_wrapper {
	padding-left: 16px;
	padding-right: 16px;
}

.game_row .game_wrapper .game_description {
	padding: 8px 16px;
	margin-top: auto;
	border-top: 1px solid #3d3e3f;
}
.game_row .game_img .game_review {
	position: absolute;
	top: 10px;
	right: 10px;
	color: #fff;
	font-family: "Clash Display";
	font-size: 20px;
	font-style: normal;
	line-height: 120%;
	border-radius: 4px;
	background: #8651ff;
	padding: 6px 12px;
}

.game_category {
	display: flex;
	/* margin-top: 15px; */
}
.game_img_title_wrapper {
	height: auto;
}

.game_category .left img {
	max-width: 40px;
}

.game_category .left {
	width: 100%;
	margin-top: auto;
	margin-bottom: auto;
	display: flex;
}
.game_category .right {
	margin-top: auto;
	margin-bottom: auto;
}

.game_category .left img {
	margin-right: 8px;
}
.game_category .left span {
	font-family: "SF Pro";
	font-size: 16px;
	font-style: normal;
	line-height: 150%;
	margin-top: auto;
	margin-bottom: auto;
}

.game_users {
	display: flex;
}
.game_users {
	display: flex;
	margin-top: 15px;
}
.game_users .img_circle {
	display: flex;
}
.game_users .img_circle > div:nth-child(1) {
	background-color: #fff;
}
.game_users .img_circle > div:nth-child(2) {
	background-color: #f6e5e5;
}
.game_users .img_circle > div:nth-child(3) {
	background-color: #fddede;
}
.game_users .user_right {
	width: 100%;
}

.game_users .img_box {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	margin-right: 10px;
}

.game_users .img_box:nth-child(2) {
	margin-left: -25px;
}
.game_users .img_box:nth-child(3) {
	margin-left: -25px;
}

.game_users .img_box img {
	width: 100%;
	height: 100%;
}

.game_users .left {
	width: 100%;
	display: flex;
}

.game_users .right {
	text-align: right;
	margin-top: auto;
	margin-bottom: auto;
}

.game_users .right span,
.game_row .open_link span {
	margin-right: 8px;
}

.game_users .user_right p.player {
	color: #fff;
	font-family: "SF Pro";
	font-size: 16px;
	font-style: normal;
	line-height: 150%;
}

.game_users .user_right p.rating {
	color: #9c9c9d;
	font-family: "SF Pro";
	font-size: 12px;
	font-style: normal;
	line-height: 150%;
}

.game_users .right a,
.game_row .open_link a {
	border-radius: 4px;
	border: 1px solid #3d3e3f;
	background: #282146;
	padding: 4px 8px;
	color: #fff;
	font-family: "SF Pro";
	font-size: 14px;
	font-style: normal;

	line-height: 150%;
	width: 75px;
	display: block;
}
.game_users .right a:hover,
.game_row .open_link a:hover {
	background: rgba(127, 127, 127, 0.5);
}

.browse_row {
	margin-top: 32px;
	margin-bottom: 32px;
}
.browse_row h3 {
	font-family: "SF Pro";
	font-size: 18px;
	font-style: normal;
	line-height: 150%;
	margin-top: auto;
	margin-bottom: auto;
}

.browse_row svg {
	margin-top: auto;
	margin-bottom: auto;
	margin-left: 8px;
}

@media screen and (max-width: 991px) {
	.game_row.non_slide .game_col {
		max-width: max-content;
	}
}
@media screen and (max-width: 767px) {
	.game_menu_row .col_left {
		max-width: max-content;
		margin-top: auto;
		margin-bottom: auto;
	}
	.game_row .game_title {
		color: #fff;
		font-family: "Clash Display";
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: 120%;
	}
}

@media screen and (max-width: 576px) {
	.game_row .game_col {
		max-width: 330px;
	}
	.game_title_wrapper,
	.game_description {
		padding-left: 8px;
		padding-right: 8px;
	}
}
@media screen and (max-width: 530px) {
	.game_menu_row {
		display: block;
	}
	.game_menu_row .col_left {
		max-width: max-content;
		margin-top: auto;
		margin-bottom: auto;
	}

	.game_menu_row .game_menu {
		text-align: left;
	}

	.game_menu_row .col_right {
		margin-top: 8px;
	}
}

@media screen and (max-width: 450px) {
	.game_menu_row .game_menu button {
		padding: 8px;
	}
}

/* Game css end */

/* Single game start */
.game_video video {
	max-width: 100%;
}

.game_single .right .game_details > div {
	border-radius: 8px;
	background: linear-gradient(108deg, rgba(255, 255, 255, 0.26) 0%, rgba(255, 255, 255, 0.07) 100%);
	padding: 12px 8px;
	backdrop-filter: blur(5px);
	margin-right: 8px;
	flex-grow: 1;
	width: 33%;
}
.game_single .left .img img {
	border-radius: 8px;
}

.game_single .right .game_details > div p:nth-child(1) {
	color: rgba(255, 255, 255, 0.6);
	font-family: "SF Pro";
	font-size: 14px;
	font-style: normal;
	font-weight: 510;
	line-height: 150%;
}
.game_single .right .game_details > div p:nth-child(2) {
	color: var(--text_white);
	font-family: "Clash Display";
	font-size: 24px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%;
}

.game_single .right .game_details {
	margin-bottom: 16px;
}

.game_single .right .game_heading .title {
	flex-grow: 1;
	margin-top: auto;
	margin-bottom: auto;
	color: #fff;

	font-family: "Clash Display";
	font-size: 24px;
	font-style: normal;
	font-weight: 500;
	line-height: 110%;
}
.game_single .right .game_heading .game_button {
	flex-grow: 1;
	text-align: right;
}

.game_single .right .game_heading .game_button button {
	color: #0f1012;
	font-family: "SF Pro";
	font-size: 14px;
	font-style: normal;
	font-weight: 510;
	line-height: 150%;
	padding: 8px;
	border-radius: 8px;
	border: 1px solid #2d333e;
	background: #fff;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	min-width: 126px;
}

.game_single .right .game_rating span:nth-child(1) {
	color: #fff;
	font-family: "SF Pro";
	font-size: 14px;
	font-style: normal;
	font-weight: 510;
	line-height: 150%;
}
.game_single .right .game_rating span:nth-child(2) {
	color: #8650ff;
	font-family: "Clash Display";
	font-size: 24px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%;
	margin-left: 5px;
}
.game_single .right .game_rating_circle {
	margin-top: -14px;
}
.game_single .right .game_rating_circle > div {
	flex-grow: 1;
	margin-bottom: 16px;
	margin-right: 8px;
}
.game_single .right .game_rating_circle > div:last-child {
	margin-right: 0px;
}
.game_single .right .game_rating_circle_item .circle_left {
	margin-right: 8px;
}
.game_single .right .game_rating_circle_item .circle_right {
	margin-top: 37px;
}
.game_single .right .game_rating_circle_item .circle_right span {
	color: #fff;
	font-family: "SF Pro";
	font-size: 14px;
	font-style: normal;
	font-weight: 510;
	line-height: 150%;
}

.game_single .right .game_rating_circle_item_btn {
	text-align: right;
	margin-top: 37px;
}

.game_single .right .game_rating_circle_item_btn button:nth-child(1) {
	color: #fff;
	border-radius: 8px;
	border: 1px solid #2d333e;
	background: var(--Gradient-Primary, linear-gradient(97deg, #f9c3ff 0%, #8650ff 65.85%));
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	padding: 8px;
}
.game_single .left h3 {
	color: #fff;
	font-family: "Clash Display";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%;
}
.game_single .left .genre {
	margin-top: 16px;
}
.game_single .left .genre_items {
	display: flex;
	flex-wrap: wrap;
	margin-top: 16px;
}
.game_single .left .genre_items span {
	color: #fff;
	font-family: "Clash Display";
	font-size: 16px;
	font-style: normal;
	line-height: 120%;
	border-radius: 12px;
	border: 1px solid #3d3e3f;
	opacity: 0.7;
	background: #0f1012;
	margin-bottom: 8px;
	margin-right: 8px;
	padding: 8px;
}

.single_game_description .game_description_heading h3 {
	color: #fff;
	font-family: "Clash Display";
	font-size: 24px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%;
}
.single_game_description .game_description_content {
	margin-top: 8px;
	white-space: pre-wrap;
}

a.outside_link {
	color: var(--background) !important;
}

.single_game_description .game_description_content p,
.single_game_description .game_description_content div,
.single_game_description .game_description_content span,
.single_game_description .game_description_content a {
	color: #fff;
	font-family: "SF Pro";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}

.buy_game h3.buy_game_title {
	color: #fff;
	font-family: "Clash Display";
	font-size: 24px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%;
	margin-bottom: 16px;
}

.game_select_box {
	margin-top: 16px;
	justify-content: center;
}
.game_select_box span {
	margin-top: auto;
	margin-bottom: auto;
	margin-right: 8px;
}

.game_select_box select {
	width: auto;
	margin-top: auto;
	margin-bottom: auto;
	background: #fff;
	color: #0f1012;
	font-family: "Clash Display";
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%;
}
.game_box .game_img_left {
	margin-right: 8px;
}
.game_box .game_img_left img {
	max-width: 96px;
}

.avialble_platform {
	border-radius: 16px;
	border: 1px solid #3d3e3f;
	background: linear-gradient(107deg, rgba(137, 84, 255, 0.12) 3.02%, rgba(137, 84, 255, 0) 225.82%);
	padding: 16px;
	margin-top: 16px;
}
.avialble_platform .title h3 {
	color: #9c9c9d;
	font-family: "Clash Display";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%;
	margin-bottom: 16px;
}
.available_platform_box {
	margin-bottom: 16px;
	max-width: 250px;
}
.available_platform_box img {
	max-width: 100px;
}
.available_platform_link {
	text-align: center;
	color: #fff;
	font-family: "Clash Display";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: 0.2px;
	border-radius: 4px;
	border: 1px solid #3d3e3f;
	background: #282146;
	padding: 8px;
	margin-top: 16px;
}

.game_review .game_review_left .review_title h3,
.game_review_breakdown .title h3 {
	color: #fff;
	font-family: "Clash Display";
	font-size: 36px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%;
	margin-bottom: 16px;
}

.review_box_row .review_user .review_user_left {
	display: flex;
	margin-right: 8px;
}

.review_box_row .review_user .review_user_left .img {
	margin-right: 8px;
}
.review_box_row .review_user .review_user_left .name {
	margin-top: auto;
	margin-bottom: auto;
}

.review_box_row .review_user .review_user_left .img img {
	width: 36px;
	height: 36px;
	border-radius: 50%;
}

.review_box_row .review_user .review_user_left {
	flex-grow: 1;
}
.review_box_row .review_user .review_user_right {
	flex-grow: 1;
	text-align: right;
}
.review_box_row .review_user .review_user_right span {
	border-radius: 4px;
	background: #8651ff;
	color: #fff;
	font-family: "Clash Display";
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%;
	padding: 6px 12px;
}

.review_box_row .review_content {
	white-space: pre-wrap;
}

.review_box_row .review_img {
	max-height: 300px;
	max-width: 300px;
}

.game_review_breakdown {
	border-radius: 16px;
	border: 1px solid #3d3e3f;
	padding: 16px;
	background: #0f1012;
	margin-bottom: 16px;
}

.game_review_breakdown .left_title {
	flex-grow: 1;
}
.game_review_breakdown .right_title {
	flex-grow: 1;
	text-align: right;
	color: #9c9c9d;
	font-family: "SF Pro";
	font-size: 16px;
	font-style: normal;
	font-weight: 510;
	line-height: 150%;
}
.game_review_breakdown_slider_wrapper {
	display: flex;
	flex-wrap: wrap;
}

.game_review_breakdown_slider_wrapper > div {
	width: 48%;
	margin-right: 15px;
}
.game_review_breakdown_slider {
	display: flex;
}

.game_review_breakdown_slider .left {
	flex-grow: 1;
	margin-right: 8px;
}
.game_review_breakdown_slider .left span {
	color: #fff;
	font-family: "Clash Display";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%;
	margin-bottom: 8px;
	display: block;
}
.game_review_breakdown_slider .right {
	text-align: right;
	margin-top: 16px;
	color: #fff;
	font-family: "Clash Display";
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%;
}

.game_single h1 {
	font-size: 36px;
}

.avergae_rating .average_rating_box_left .average_rating_box {
	flex-wrap: wrap;
}
.avergae_rating .average_rating_box_left .average_rating_box > div {
	margin-bottom: 8px;
}

.average_rating_box_item {
	width: 18.4px;
	height: 46px;
	background: linear-gradient(97deg, #f9c3ff 0%, #8650ff 65.85%);
	border-radius: 4px;
	margin-right: 16px;
}
.average_rating_box_item.empty {
	background: #fff;
}

.avergae_rating .average_rating_box_left {
	flex-grow: 1;
}
.avergae_rating .average_rating_box_right {
	flex-grow: 1;
	text-align: right;
	margin-top: auto;
	margin-bottom: auto;
}
.avergae_rating .average_rating_box_right span {
	border-radius: 4px;
	background: #8651ff;
	color: #fff;
	font-family: "Clash Display";
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%;
	padding: 6px 12px;
}

.avergae_rating_horizontal {
	display: flex;
	margin-top: 16px;
}
.avergae_rating_horizontal span {
	margin-top: auto;
	margin-bottom: auto;
	min-width: 20px;
}

.avergae_rating_horizontal span:last-child {
	min-width: 30px;
}

.avergae_rating_horizontal .progress_box {
	margin-top: auto;
	margin-bottom: auto;
	flex-grow: 1;
	margin-left: 8px;
	margin-right: 8px;
}

@media screen and (max-width: 991px) {
	.game_single .right .game_details > div p:nth-child(2) {
		font-size: 18px;
	}
	.available_platform_box_wrapper {
		display: flex;
		flex-wrap: wrap;
	}
	.available_platform_box_wrapper > div {
		flex-grow: 1;
		margin-right: 8px;
		margin-bottom: 16px;
		width: 48%;
	}
}
@media screen and (max-width: 767px) {
	.game_single .left .img img {
		max-width: 300px;
	}
	.game_review .game_review_left .review_title h3,
	.game_review_breakdown .title h3 {
		font-size: 24px;
	}

	.game_review_breakdown_slider_wrapper > div {
		width: 100%;
	}
	.buy_game .game_box {
		flex-wrap: wrap;
	}
	.buy_game .game_box .game_img_left {
		margin-bottom: 16px;
	}
}
@media screen and (max-width: 576px) {
	.game_single .right .game_details {
		flex-wrap: wrap;
	}
	.game_single .right .game_details > div {
		margin-bottom: 16px;
	}
	.game_single .right .game_rating_circle {
		flex-wrap: wrap;
	}

	.game_single .right .game_rating_circle .game_rating_circle_item {
		width: 100%;
	}
	.game_single .right .game_rating_circle_item_btn {
		text-align: left;
	}
	.game_review_breakdown .title {
		display: block;
	}
	.game_review_breakdown .right_title {
		text-align: left;
		margin-bottom: 16px;
	}
	.average_rating_box_item {
		width: 12px;
	}
}
/* Single game end */

/* News css start */
.row.nonslider_news .news_col {
	margin-bottom: 32px;
}
.news_heading_row {
	margin-top: 32px;
	margin-bottom: 32px;
}
.news_heading_row .col {
	margin-top: auto;
	margin-bottom: auto;
}
.news_heading_row .news_heading_col_left h3 {
	color: #fff;
	font-family: "Clash Display";
	font-size: 48px;
	font-style: normal;
	line-height: 120%;
}

.news_heading_row .news_heading_right {
	display: flex;
	justify-content: end;
	border-radius: 8px;
	border: 1px solid #3d3e3f;
	box-shadow: 0.25px 1.25px 0px 0.75px rgba(255, 255, 255, 0.1) inset;
	padding: 8px 10px;
	width: max-content;
	margin-left: auto;
}

.news_heading_row .news_heading_right .sort {
	margin-top: auto;
	margin-bottom: auto;
}
.news_heading_row .news_heading_right .sort p {
	margin-right: 10px;
}

.news_heading_row .news_heading_right select {
	padding: 8px 12px;
	border-radius: 8px;
	border: 1px solid rgba(255, 255, 255, 0.06);
	background: #fff;
	outline: none;
	color: #000;
}

.news_wrapper {
	box-shadow: 0.25px 1.25px 0px 0.75px rgba(255, 255, 255, 0.1) inset;
	border: 1px solid #3d3e3f;
	border-radius: 8px;
	max-width: 350px;
	display: flex;
	flex-direction: column;
	height: 100%;
}

.news_wrapper .news_img {
	text-align: center;
}
.news_wrapper .news_img img {
	max-width: 100%;
}

.news_wrapper .news_content {
	padding: 16px;
}

.news_wrapper a.news_category {
	border-radius: 2px;
	background: radial-gradient(93.93% 93.94% at 6.07% 48.57%, #3c3c3c 0%, #a2a2a2 100%), linear-gradient(91deg, #181622 -31.56%, #302283 125.13%);
	font-family: "Clash Display";
	font-size: 14px;
	font-style: normal;

	line-height: 120%;
	padding: 2px 8px;
	margin-top: 16px;
	margin-bottom: 16px;
	display: inline-block;
}

.news_wrapper a.news_category:hover {
	color: var(--text_white);
}

.news_wrapper .news_content h3 {
	color: #fff;
	font-family: "Clash Display";
	font-size: 20px;
	font-style: normal;

	line-height: 120%;
	margin-bottom: 16px;
}
.news_wrapper .news_content .description {
	font-family: "SF Pro";
	font-size: 16px;
	font-style: normal;

	line-height: 150%;
}

.news_wrapper .news_user .img_left {
	width: 40px;
	height: 40px;
	margin-right: 8px;
}

.news_wrapper .news_user .img_left img {
	max-width: 100%;
	max-height: 100%;
	width: 40px;
	height: 40px;
	border-radius: 50%;
}

.news_user_wrapper {
	padding: 16px;
	border-top: 1px solid #3d3e3f;
	margin-top: auto;
}
.news_user_wrapper .news_user {
	flex-wrap: wrap;
}
.news_user_wrapper .news_user > div {
	margin-bottom: 8px;
}
.news_user_wrapper .news_user .left {
	flex-grow: 1;
	margin-right: 8px;
}

.news_user_wrapper .img_right .name {
	color: #fff;
	font-family: "SF Pro";
	font-size: 16px;
	font-style: normal;

	line-height: 150%;
}

.news_user_wrapper .img_right .date {
	color: #9c9c9d;
	font-family: "SF Pro";
	font-size: 12px;
	font-style: normal;
	line-height: 150%;
}

.news_user_wrapper .right a {
	width: 75px;
	display: block;
	border-radius: 4px;
	border: 1px solid #3d3e3f;
	background: #282146;
	padding: 8px;
	color: #fff;
	font-family: "SF Pro";
	font-size: 14px;
	font-style: normal;
	line-height: 150%;
}
.news_user_wrapper .right a:hover {
	background: rgba(127, 127, 127, 0.5);
}
.news_user_wrapper .right a span {
	margin-right: 8px;
}

@media screen and (max-width: 991px) {
	.nonslider_news .news_col {
		max-width: max-content;
	}
}
@media screen and (max-width: 767px) {
	.news_heading_row .col h3 {
		color: #fff;
		font-family: "Clash Display";
		font-size: 20px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		letter-spacing: 0.2px;
	}
	.news_heading_row .news_heading_right {
		padding: 4px 8px;
	}
	.news_user_wrapper {
		padding: 16px 8px;
	}
}
/* News css end */

/* Single news start */

.single_news_row .news_heading h1 {
	color: #fff;
	font-family: "Clash Display";
	font-size: 36px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: 0.2px;
}

.single_news_row .news_img img {
	max-width: 100%;
}

.single_news_row .game_description p,
.single_news_row .game_description span,
.single_news_row .game_description a,
.single_news_row .game_description h1,
.single_news_row .game_description h2,
.single_news_row .game_description h3,
.single_news_row .game_description h4,
.single_news_row .game_description h5,
.single_news_row .game_description h6,
.single_news_row .game_description div,
.single_news_row .game_description li {
	color: #fff !important;
}

.news_author .img {
	margin-right: 8px;
}
.news_author img {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	margin-right: 8px;
}

.news_author .name span {
	color: #fff;
	font-family: "SF Pro";
	font-size: 16px;
	font-style: normal;
	line-height: 150%;
}

.news_extra .news_tags {
	flex-wrap: wrap;
}
.news_extra .news_tags .tag_item a:hover {
	color: var(--btn_hover_color);
}
.news_extra .news_tags .tag_item {
	border-radius: 4px;
	background: radial-gradient(93.93% 93.94% at 6.07% 48.57%, #3c3c3c 0%, #a2a2a2 100%), linear-gradient(91deg, #181622 -31.56%, #302283 125.13%);
	font-family: "Clash Display";
	font-size: 14px;
	font-style: normal;
	line-height: 120%;
	padding: 2px 8px;
	margin-right: 8px;
	display: inline-block;
}
.single_news_row .news_category a {
	border-radius: 4px;
	background: radial-gradient(93.93% 93.94% at 6.07% 48.57%, #3c3c3c 0%, #ff1616 100%), linear-gradient(91deg, #181622 -31.56%, #302283 125.13%);
	font-family: "Clash Display";
	font-size: 14px;
	font-style: normal;
	line-height: 120%;
	padding: 2px 8px;
	margin-right: 8px;
	display: inline-block;
	margin-bottom: 8px;
}
.single_news_row .news_category a:hover {
	color: var(--btn_hover_color);
}

.news_reaction .love {
	margin-right: 8px;
	cursor: pointer;
}
.news_reaction .comment {
	cursor: pointer;
}
.news_reaction svg {
	margin-right: 4px;
}
.news_reaction span {
	color: #fff;
	font-family: "Clash Display";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: 0.2px;
}

.comment_box .comment_button {
	text-align: right;
	margin-top: 8px;
}
.comment_box .comment_button button {
	padding: 8px;
	border-radius: 4px;
	border: 2px solid var(--border_sec_color);
}
.comment_enter textarea {
	height: 100px;
}
.comment_box .comment_button button:hover {
	background-color: var(--background);
	color: #fff;
}

@media screen and (max-width: 767px) {
	.single_news_row .news_heading h1 {
		font-size: 24px;
	}
}

/* Single news end */

/* Submit game start */

.submit_game_heading_row h1 {
	font-size: 36px;
}
.submit_game_heading_row svg {
	color: #fff;
	margin-right: 8px;
}
.submit_game_row .game_input {
	margin-bottom: 16px;
}

.submit_game_row .game_half_input {
	display: flex;
	flex-wrap: wrap;
}
.submit_game_row .css-hlgwow input {
	height: 26px;
}
.submit_game_row label .input_star {
	color: #fff;
}
.submit_game_row .game_half_input > div {
	flex-grow: 1;
	width: 50%;
	padding-right: 16px;
}
.submit_game_row .game_half_input > div {
	padding-right: 16px;
}

.upload_input {
	background: #fff;
	width: max-content;
	margin: auto;
	padding: 8px;
	border-radius: 8px;
	margin-bottom: 8px;
}
.upload_input svg {
	margin-right: 4px;
	color: #000;
}
.upload_input span {
	color: #000;
}

.game_input.single_input,
.submit_game_row .video_input,
.submit_game_row .game_submit_button,
.submit_game_row .game_description,
.submit_game_row .store_box {
	margin-right: 16px;
}
.video_input {
	border: 1px solid var(--border_sec_color);
	padding: 8px;
	text-align: center;
	border-radius: 8px;
}
.video_input img {
	max-height: 180px !important;
	max-width: 100%;
	width: auto !important;
}

.video_input div[role="presentation"] {
	margin-top: 16px;
	height: max-content;
}

.submit_game_row .game_input .multiselect .css-13cymwt-control {
	background-color: var(--background_sec);
	border-color: var(--border_sec_color);
}
.submit_game_row .game_input .multiselect .css-t3ipsp-control {
	background-color: var(--background_sec);
	border-color: var(--border_sec_color);
	box-shadow: unset;
}
.submit_game_row .game_input.game_description textarea {
	height: 150px;
}
.submit_game_row .game_submit_button button {
	background: linear-gradient(97.36deg, #f9c3ff 0%, #8650ff 65.85%);
	padding: 12px 16px;
	margin-right: 8px;
	border-radius: 8px;
}
.submit_game_row .game_submit_button button.white {
	background: #fff;
	margin-right: 8px;
}

.submit_game_row .game_submit_button button.text-white {
	margin-right: 0px;
}
.submit_game_row .game_submit_button button.text-white {
	margin-right: 0px;
}

.submit_game_row .store_box {
	border: 1px solid var(--border_sec_color);
	padding: 16px;
	margin-bottom: 16px;
	position: relative;
	border-radius: 4px;
}
.submit_game_row .store_box svg {
	position: absolute;
	color: #fff;
	right: 12px;
	top: 5px;
	cursor: pointer;
}

@media screen and (max-width: 576px) {
	.submit_game_row .game_half_input > div {
		width: 100%;
	}
}

/* Submit game end */

/* Slider css end */

/* Profile menu css start */

.profile_menu_box_wrapper.flex .admin_left {
	margin-right: 16px;
}
.profile_menu_box_wrapper.flex .admin_right {
	flex-grow: 1;
}

.admin_left,
.profile_menu_box_wrapper {
	height: 100%;
}
.mobile_menu_cion {
	display: none;
}

.profile_menu_box {
	border-radius: 8px;
	border: 1px solid #3d3e3f;
	background: var(--background_sec);
	padding: 16px;
	width: max-content;
	height: 100%;
	position: relative;
}

.profile_menu_item_border {
	border-radius: 12px;
	background: var(--background_sec);
	border: solid 1px transparent;
	background-origin: border-box;
	background-clip: content-box, border-box;
	background-image: linear-gradient(#08090b, #08090b), linear-gradient(97deg, #f9c3ff 0%, #8650ff 65.85%);
	margin-bottom: 16px;
}
.profile_menu_link {
	display: flex;
	padding: 12px 16px;
}
.profile_menu_link > div {
	margin-top: auto;
	margin-bottom: auto;
}
.profile_menu_link .left {
	margin-right: 8px;
}
.profile_menu_link p {
	color: var(--btn_color);
	font-family: "Clash Display";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: 0.2px;
}

.profile_menu_item.logout {
	/* position: absolute;
	bottom: 0px; */
	width: max-content;
}

.profile_left {
	margin-right: 24px;
}
.profile_right .heading {
	display: flex;
}
.profile_right .heading svg {
	margin-top: auto;
	margin-bottom: auto;
	margin-right: 8px;
}

.profile_right .heading span {
	margin-top: auto;
	margin-bottom: auto;
	color: #fff;
	font-family: "Clash Display";
	font-size: 36px;
	font-style: normal;
	line-height: normal;
	letter-spacing: 0.2px;
}

.profile_image {
	margin-top: 24px;
	cursor: pointer;
	width: max-content;
}

.profile_image .img_wrapper {
	position: relative;
	border: 1px solid #fff;
	border-radius: 50%;
}
.profile_image .img_wrapper svg {
	color: #fff;
	position: absolute;
	top: 4px;
	right: 4px;
}

.profile_image img {
	width: 90px;
	height: 90px;
	border-radius: 50%;
}
.profile_image input[type="file"] {
	display: none;
}

.profile_image > div {
	margin-top: auto;
	margin-bottom: auto;
	margin-right: 16px;
}
.profile_image .middle,
.profile_image .right {
	border-radius: 8px;
	border: 1px solid #fff;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	padding: 8px 16px;
}
.profile_image .middle span,
.profile_image .right span {
	margin-top: auto;
	margin-bottom: auto;
	font-family: "Clash Display";
	font-size: 14px;
	font-style: normal;
	letter-spacing: 0.2px;
}
.profile_image .middle svg,
.profile_image .right svg {
	margin-top: auto;
	margin-bottom: auto;
	margin-right: 8px;
}

.profile_input_box {
	margin-top: 16px;
}

.profile_input_wrapper {
	flex-wrap: wrap;
}
.profile_input_wrapper > div {
	width: 47%;
	margin-right: 16px;
	flex-grow: 1;
}
.profile_right .profile_btn {
	margin-top: 32px;
	text-align: right;
}
.profile_right .profile_btn button {
	margin-right: 16px;
	padding: 8px 16px;
	color: var(--text_dark);
	font-family: "Clash Display";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%;
	letter-spacing: 0.2px;
	border-radius: 8px;
	border: 1px solid var(--border_sec_color);
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	padding: 10px 30px;
}
.profile_right .profile_btn button:nth-child(2) {
	background: linear-gradient(97deg, #f9c3ff 0%, #8650ff 65.85%);
	color: var(--text_white);
}

@media screen and (max-width: 991px) {
	.mobile_menu_cion {
		display: block;
	}
	.mobile_menu_cion .svg_border {
		border-radius: 8px;
		background: var(--background_sec);
		border: solid 2px transparent;
		background-origin: border-box;
		background-clip: content-box, border-box;
		background-image: linear-gradient(#08090b, #08090b), linear-gradient(97deg, #f9c3ff 0%, #8650ff 65.85%);
		margin-bottom: 8px;
		width: max-content;
	}
	.mobile_menu_cion svg {
		display: block;
		cursor: pointer;
		margin: 8px;
		color: #fff;
	}

	.profile_menu_box_wrapper {
		display: block;
	}

	.profile_menu_box_wrapper.flex .admin_left {
		position: relative;
	}
	.profile_menu_box {
		position: absolute;
		height: max-content;
		z-index: 1;
		display: block;
		width: max-content;
	}
	.profile_menu_box > div {
		margin-right: 8px;
	}
	.profile_menu_item.logout {
		border-radius: 12px;
		background: var(--background_sec);
		border: solid 1px transparent;
		background-origin: border-box;
		background-clip: content-box, border-box;
		background-image: linear-gradient(#08090b, #08090b), linear-gradient(97deg, #f9c3ff 0%, #8650ff 65.85%);
		position: unset;
		width: auto;
		height: max-content;
	}
}

@media screen and (max-width: 767px) {
	.profile {
		display: block;
	}
	.profile_left {
		margin-right: 0px;
		margin-bottom: 16px;
	}

	.profile_right .heading span {
		font-size: 24px;
	}
	.profile_right .heading svg {
		width: 24px;
		height: 24px;
	}
}

@media screen and (max-width: 767px) {
	.profile_image {
		flex-wrap: wrap;
		justify-content: center;
	}
	.profile_image .left {
		width: 100%;
		margin-bottom: 16px;
		text-align: center;
	}
}
/* Profile menu css end */

/* Reviews css start */

.reviews_box {
	margin-top: 32px;
	margin-bottom: 32px;
}

.reviews_box .reviews_heading {
	display: flex;
}
.reviews_box .reviews_heading svg {
	margin-top: auto;
	margin-bottom: auto;
}
.reviews_box .reviews_heading span {
	color: #fff;
	font-family: "Clash Display";
	font-size: 36px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: 0.2px;
	margin-top: auto;
	margin-bottom: auto;
}
.reviews_box .reviews_heading svg {
	margin-right: 8px;
}

.reviews_box > div {
	flex-grow: 1;
}

.reviews_box .right {
	justify-content: end;
}
.reviews_box .right > div {
	margin-top: auto;
	margin-bottom: auto;
}
.reviews_box .right .search_left {
	margin-right: 8px;
}
.reviews_box .right .search_left button {
	margin-left: 4px;
	padding: 8px;
}
.reviews_box .right .search_left input {
	border: 1px solid var(--border_color);
	outline: 1px solid var(--border_color);
	width: 240px;
	height: 36px;
}
.reviews_box .right .search_right .filter_icon {
	background: var(--btn_bg_color);
	display: flex;
	padding: 8px;
	border-radius: 8px;
	cursor: pointer;
}
.reviews_box .right .search_right .filter_icon svg {
	margin-top: auto;
	margin-bottom: auto;
}
.reviews_box .right .search_right .filter_icon span {
	color: var(--text_dark);
	text-align: center;
	font-family: "Clash Display";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: -0.154px;
	margin-top: auto;
	margin-bottom: auto;
	margin-right: 4px;
}

.review_row {
	margin-bottom: 32px;
}
.review_row .left {
	margin-right: 10px;
}

.review_row .right {
	flex-grow: 1;
}

.review_row .left img {
	max-width: 120px;
	border-radius: 4px;
}

.review_row .right .heading > div {
	flex-grow: 1;
}

.review_row .right .heading .heading_left {
	margin-bottom: 8px;
}
.review_row .right .heading .heading_left > div {
	flex-grow: 1;
}

.review_row .right .heading .heading_left .heading_title {
	margin-bottom: 8px;
	margin-right: 8px;
}

.review_row .right .heading .heading_left .heading_title h2 {
	color: #fff;
	font-family: "Clash Display";
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%;
	letter-spacing: 0.2px;
}

.review_row .right .heading .heading_right {
	justify-content: end;
}

.review_row .right .heading .heading_right span {
	margin-right: 4px;
	color: #fff;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 120%; /* 16.8px */
	letter-spacing: 0.2px;
	font-family: "Clash Display";
}

.review_row .right .heading .heading_right .heading_status_button {
	background: #d7f7c2;
	width: max-content;
	flex-grow: unset;
	margin-right: 10px;
	padding: 0px 8px 4px 8px;
	border-radius: 4px;
	height: max-content;
}

.review_row .right .heading .heading_right .heading_status_button span {
	font-family: "SF Pro";
	font-size: 12px;
	font-style: normal;
	font-weight: 510;
	line-height: 150%;
	color: var(--text-success);
	margin-left: 4px;
}

.review_row .right .description {
	flex-grow: 1;
	margin-right: 8px;
}
.review_row .right .description,
.review_row .right .description p {
	color: var(--text_white);
	font-family: "SF Pro";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%; /* 21px */
	letter-spacing: 0.2px;
}
.review_row .right .reviews_tag {
	margin-top: 12px;
}
.review_row .right .reviews_tag .tag_left {
	flex-grow: 1;
	flex-wrap: wrap;
}
.review_row .right .reviews_tag .tag_right {
	flex-grow: 1;
	text-align: right;
}

.review_row .right .tag_right span {
	display: inline-block;
	background: var(--background);
	padding: 7px 15px;
	border-radius: 4px;
	color: #fff;
	font-family: "Clash Display";
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: 0.11px;
}

.review_row .right .reviews_tag .tag_left > div {
	margin-right: 8px;
}

.review_row .right .reviews_tag .tag_left .tag_item span.name {
	color: #9c9c9d;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%; /* 14.4px */
	letter-spacing: 0.2px;
	margin-right: 4px;
}

.review_row .right .reviews_tag .tag_left .tag_item span.value {
	color: var(--text_white);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%; /* 21px */
	letter-spacing: 0.2px;
}
.review_row .right .heading_dot {
	cursor: pointer;
	margin-right: -8px;
	position: relative;
}
.review_row .right .heading_dot .review_edit_box {
	position: absolute;
	right: 0px;
	z-index: 1;
	background: #000;
	margin-top: 5px;
	border-radius: 4px;
	color: #fff;
	width: 100px;
	border: 2px solid #8651ff;
}

.review_row .right .heading_dot .review_edit_box p {
	padding: 4px 8px;
	background-color: rgba(127, 127, 127, 0.5);
	border: 1px solid var(--background_sec);
	margin-bottom: 4px;
}

.review_row .right .heading_dot .review_edit_box p:last-child {
	margin-bottom: 0px;
}

.reviews_box .right .search_right {
	position: relative;
}

.filter_menu {
	position: absolute;
	right: 0px;
	z-index: 1;
	margin-top: 6px;
	padding: 16px;
	background: #0f1012;
	border: 1px solid var(--border_color);
	border-radius: 4px;
	top: 42px;
}

.home_search .filter_menu {
	width: 100%;
}

.home_search .filter_menu .filter_input_wrapper {
	display: flex;
	flex-wrap: wrap;
}

.home_search .filter_menu .filter_input_wrapper > div {
	width: 33.33%;
	padding: 0px 8px;
}
.home_search .filter_menu .filter_button {
	padding: 0px 8px;
}

.filter_menu .title .left {
	flex-grow: 1;
}
.filter_menu .title .right {
	flex-grow: 1;
	text-align: right;
}
.filter_menu .title .right svg {
	cursor: pointer;
}
.filter_menu h3 {
	font-family: "Clash Display";
	font-size: 24px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: 0.2px;
	margin-right: 8px;
}

.filter_menu .filter_input {
	margin-top: 8px;
}
input[type="date"] {
	color-scheme: dark;
}
.filter_menu .filter_input .filter_checkbox input {
	margin-top: auto;
	margin-bottom: auto;
	margin-right: 7px;
}

.filter_menu .filter_input .filter_checkbox label {
	margin-top: auto;
	margin-bottom: auto;
}

.filter_button .clear_filter button {
	background: #fff;
	color: #000;
}

@media screen and (max-width: 991px) {
	.review_row .right .heading {
		flex-wrap: wrap;
	}
	.review_row .right .heading .heading_left {
		flex-wrap: wrap;
	}
}
@media screen and (max-width: 767px) {
	.reviews_box {
		margin-top: 0px;
	}

	.reviews_box {
		display: block;
	}
	.reviews_box .right {
		justify-content: unset;
		margin-top: 16px;
	}
	.reviews_box .right .search_left input {
		width: 220px;
	}

	.reviews_box .reviews_heading span {
		font-size: 24px;
	}
	.reviews_box .reviews_heading svg {
		width: 24px;
		height: 24px;
	}
}

@media screen and (max-width: 576px) {
	.review_row {
		display: block;
	}
	.review_row .right .heading .heading_right {
		justify-content: unset;
	}
	.review_row .left img {
		margin-bottom: 8px;
	}
}
/* Reviews css end */

/* Privacy css end */

.privacy_row {
	padding-top: 32px;
	padding-bottom: 32px;
}
.privacy_row .privacy_title {
	display: flex;
}

.privacy_row .privacy_title svg {
	margin-top: auto;
	margin-bottom: auto;
	margin-right: 8px;
}
.privacy_row .privacy_title h1 {
	margin-top: auto;
	margin-bottom: auto;
	color: #fff;
	font-family: "Clash Display";
	font-size: 36px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: 0.2px;
}
.privacy_row .privacy_title {
	margin-bottom: 16px;
}
.privacy_row .privacy_item {
	margin-bottom: 32px;
}

.privacy_row .privacy_item h2 {
	color: #fff;
	font-family: "Clash Display";
	font-size: 24px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: 0.2px;
	margin-bottom: 16px;
}
.privacy_row .privacy_item p,
.privacy_row .privacy_item ul li {
	color: #fff;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	letter-spacing: 0.2px;
}

.privacy_row .privacy_item ul li {
	list-style: disc;
}

/* Privacy css end */

/* Login css start */

.login_row .login_box {
	background: rgba(17, 18, 24, 0.9);
	padding: 30px;
	max-width: 460px;
	margin: auto;
	box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
	margin-top: 32px;
	margin-bottom: 32px;
	border-radius: 16px;
	margin-left: 295px;
}

.login_row .login_col {
	z-index: 3;
}

.login_shadow {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7);
}

.login_row .login_col h1 {
	margin-top: 16px;
	margin-bottom: 16px;
}
.login_row .login_col p {
	margin-top: 16px;
	margin-bottom: 16px;
}
.login_row .login_col .login_input {
	margin-bottom: 16px;
	position: relative;
}
.login_row .login_col .login_input {
	margin-bottom: 16px;
}
.login_row .login_col .login_input input {
	height: 48px;
	border: 1px solid var(--border_color);
	background-color: #000;
	padding-left: 36px;
	font-size: 16px;
}
.login_row .login_col .login_input label {
	display: block;
}
.login_row .login_col .login_input svg {
	position: absolute;
	margin-top: 12px;
	margin-left: 8px;
}
.login_row .login_col .login_input svg.eye {
	right: 8px;
	cursor: pointer;
}
.login_row .login_col .login_input .eye_input {
	padding-right: 36px;
}

.login_row .login_col h1 {
	margin-bottom: 10px;
}

.login_row .login_col .login_btn .button {
	background-color: var(--background);
	display: block;
	margin-top: 16px;
	margin-bottom: 8px;
	width: 100%;
	border-radius: 8px;
	padding: 12px 16px;
	font-family: "Clash Display";
	font-size: 18px;
}
.login_row .login_col .login_btn .button.circle {
	padding: 7px 16px;
}

.login_row .login_col .forget_link {
	text-align: right;
}
.login_row .login_col .forget_link a:hover {
	color: var(--btn_hover_color);
}
.login_row svg {
	color: #fff;
}
.login_row .line {
	flex-grow: 1;
	border-top: 2px solid #fff;
	margin-top: auto;
	width: 100%;
}

.login_row h5.flex-grow {
	margin-left: 8px;
	margin-right: 8px;
}

.login_row .already_account a {
	color: var(--text_white);
	font-size: 16px;
	text-transform: uppercase;
	text-decoration: underline;
}

.external_login a {
	background-color: #000;
	border: 1px solid var(--border_color);
	display: flex;
	padding: 12px 8px;
	border-radius: 8px;
	margin-bottom: 16px;
}

.external_login a:hover {
	color: #fff;
}
.external_login svg {
	margin-right: 8px;
}
.external_login {
	color: #fff;
	margin-bottom: 16px;
}
.login_anchor {
	color: inherit;
	text-decoration: underline;
}
@media screen and (max-width: 576px) {
	.login_row .login_box {
		padding: 30px 15px;
	}
}

/* Login css end */

/* Contact css start */

.contact_row .contact_col_left .input_item {
	position: relative;
	margin-bottom: 16px;
}

.contact_row .contact_col_left .input_item label {
	display: block;
}
.contact_row .contact_col_left .input_item svg {
	position: absolute;
	left: 12px;
	top: 36px;
	color: #fff;
}
.contact_row .contact_col_left .input_item input {
	border: 1px solid var(--border_color);
	height: 48px;
	padding-left: 36px;
	font-size: 16px;
}
.contact_row .contact_col_left .input_item textarea {
	border: 1px solid var(--border_color);
	padding-left: 36px;
	height: 128px;
	font-size: 16px;
}
.contact_row .contact_col_left .input_item button {
	display: block;
	width: 100%;
	padding: 8px 16px;
	background: var(--background);
	color: #fff;
	font-family: "Clash Display";
	font-size: 16px;
}
.contact_row .contact_col_left .input_item button svg {
	position: unset;
}
.contact_row .contact_col_right {
	text-align: right;
}
/* Contact css end */

/* Popup review start */
.popup_wrapper {
	display: block;
	position: fixed;
	width: 100%;
	background: rgba(0, 0, 0, 0.5);
	height: 100%;
	z-index: 4;
}

.game_review_slider {
	overflow: auto;
	max-width: 800px;
	margin: auto;
	background: #000;
	padding: 16px;
	overflow-y: scroll;
	height: 100%;
}
.review_popup_text {
	position: relative;
	margin-top: 16px;
}
.review_popup_text svg {
	position: absolute;
	top: 5px;
	right: 20px;
	cursor: pointer;
	color: #fff;
	z-index: 5;
}
.review_popup_text p {
	margin-top: 8px;
	margin-bottom: 8px;
}
.game_review_slider .review_input_wrapper {
	display: flex;
	flex-wrap: wrap;
}

.game_review_slider .game_input {
	position: relative;
	flex-grow: 1;
	padding: 0px 16px;
	max-width: 50%;
	width: 50%;
}

.game_review_slider .game_input img {
	max-height: 190px;
}

.game_review_slider .game_input input[type="range"] {
	padding: 0px;
	border: unset;
}
.game_review_slider .game_input .total_point {
	position: absolute;
	right: 16px;
	top: 0px;
}
.game_review_slider .game_input .image_input {
	border: 1px solid #fff;
	padding: 8px;
	text-align: center;
	border-radius: 8px;
	margin-bottom: 16px;
}
.game_review_slider .game_input textarea {
	min-height: 208px;
	border-color: #fff;
}

@media screen and (max-width: 576px) {
	.game_review_slider .review_input_wrapper {
		display: block;
	}
	.game_review_slider .game_input {
		width: 100%;
		max-width: 100%;
	}
	.review_popup_text svg {
		top: -30px;
	}
}
/* Popup review end */

/* Loader start */

/* HTML: <div class="loader"></div> */
.loader {
	height: 4px;
	width: 130px;
	--c: no-repeat linear-gradient(#6100ee 0 0);
	background: var(--c), var(--c), #d7b8fc;
	background-size: 60% 100%;
	animation: l16 3s infinite;
	margin: auto;
}
@keyframes l16 {
	0% {
		background-position: -150% 0, -150% 0;
	}
	66% {
		background-position: 250% 0, -150% 0;
	}
	100% {
		background-position: 250% 0, 250% 0;
	}
}

.loader_circle {
	width: 30px;
	padding: 4px;
	aspect-ratio: 1;
	border-radius: 50%;
	background: #25b09b;
	--_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
	-webkit-mask: var(--_m);
	mask: var(--_m);
	-webkit-mask-composite: source-out;
	mask-composite: subtract;
	animation: l3 1s infinite linear;
	margin: auto;
}
@keyframes l3 {
	to {
		transform: rotate(1turn);
	}
}
/* Loader end */

/* Pagination css start */
.pagination .page-link {
	background-color: #000;
	color: #fff;
	cursor: pointer;
}
.pagination .page-link.active {
	background-color: var(--background);
	border-color: inherit;
}
.pagination .page-link.disabled {
	opacity: 0.6;
}
/* Pagination css end */

/* Rechart css start */

.recharts-tooltip-label,
.recharts-tooltip-item span {
	color: #000;
}

.graph_select_wrapper {
	border: solid 2px transparent;
	background-origin: border-box;
	background-clip: content-box, border-box;
	background-image: linear-gradient(#08090b, #08090b), linear-gradient(97deg, #f9c3ff 0%, #8650ff 65.85%);
	border-radius: 8px;
}

.graph_select_wrapper select {
	border: unset !important;
	outline: unset !important;
}
/* Rechart css end */

/* Compare game start */
.compare_game_input .compare_left,
.compare_game_input .compare_right {
	position: relative;
}

.compare_game_input label {
	color: #fff;
	font-family: "Clash Display";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%;
	letter-spacing: 0.2px;
}

table .compare_loop_item {
	display: flex;
	flex-wrap: wrap;
}
table .compare_loop_item span {
	margin-right: 8px;
	min-width: 50px;
	text-align: center;
	margin-bottom: 8px;
	padding: 4px 8px;
	margin-right: 4px;
	border-radius: 8px;
	background: radial-gradient(93.93% 93.94% at 6.07% 48.57%, #3c3c3c 0%, #ff1616 100%), linear-gradient(91deg, #181622 -31.56%, #302283 125.13%);
	cursor: pointer;
}

.search_product_row {
	position: relative;
}
.search_bar_items .search_result {
	position: absolute;
	top: 80px;
	left: 0px;
	background-color: #000;
	z-index: 50;
	box-shadow: 0px 12px 61px -7px rgba(4, 6, 15, 0.15);
	border-radius: 8px;
	min-width: 250px;
	min-height: 150px;
	max-height: 450px;
	max-width: 600px;
	overflow: auto;
	border: 1px solid var(--border_color);
}
.search_bar_items .search_result .result_anchor {
	display: inline-block;
	padding: 5px !important;
	background: var(--btn_color);
	color: #fff;
	border-radius: 8px;
	text-align: center;
}

.search_bar_items .search_result .search_result_item {
	border-bottom: 1px solid var(--border_color);
	padding: 10px;
	cursor: pointer;
	position: relative;
}

.search_bar_items .search_result .search_result_img {
	margin-right: 8px;
	margin-top: auto;
	margin-bottom: auto;
	flex-grow: 1;
}
.search_bar_items .search_result .search_result_img img {
	max-width: 100px;
}
.search_bar_items .search_result .search_result_content {
	margin-top: auto;
	margin-bottom: auto;
}
.search_bar_items .search_result .search_result_content h3 {
	color: #fff;
	font-family: "Nunito Sans";
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: 27px;
}

.selected_product .search_result {
	border: 1px solid var(--btn_color);
	position: unset;
	min-height: unset;
	max-height: unset;
	margin-top: 10px;
}

.selected_product .search_bar_items .search_result .search_result_item {
	border: unset;
}

/* Compare game end */

/* Home search css start */
.home_search .input_left {
	position: relative;
}

.home_search .input_left .right {
	position: relative;
}
.home_search .flex .input_left .left input {
	border: unset;
	outline: unset;
}
/* Home search css end */

/* Platform css start */
.platform_box {
	border-radius: 8px;
	border: 1px solid #3d3e3f;
	max-width: 350px;
	height: 100%;
	display: flex;
	flex-direction: column;
}
.platform_box h3 {
	text-align: center;
	margin-top: 16px;
	margin-bottom: 16px;
}
.platform_box img {
	text-align: center;
}

/* Platform css end */
