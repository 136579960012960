.admin_container .game_heading svg {
	margin-right: 8px;
}

.admin_container .game_heading {
	margin-bottom: 16px;
}
.admin_container .game_heading h1 {
	color: #fff;
	font-family: "Clash Display";
	font-size: 36px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: 0.2px;
}

.admin_table table {
	width: 100%;
}

.admin_table table th,
.admin_table table td {
	border: 1px solid #3d3e3f;
	padding: 8px;
	min-width: 150px;
}

.admin_table table th svg:nth-child(1) {
	margin-right: 4px;
}
.admin_table table th svg:nth-child(3) {
	margin-left: 4px;
	cursor: pointer;
}

.admin_table table th span {
	color: #fff;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 150%;
}
.admin_table table td {
	color: #fff;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}

.admin_table table td.game_status span {
	min-width: 80px;
	border-radius: 6px;
	text-align: center;
	display: inline-block;
}

.admin_table table td.game_status.Pending span {
	background: radial-gradient(93.93% 93.94% at 6.07% 48.57%, #3c3c3c 0%, #ff1616 100%), linear-gradient(91deg, #181622 -31.56%, #302283 125.13%);
}
.admin_table table td.game_status.Published span {
	background: radial-gradient(93.93% 93.94% at 6.07% 48.57%, #185522 0%, #8df100 100%), linear-gradient(91deg, #4424e4 -31.56%, #1c7d4a 125.13%);
}
.admin_table table td.game_status.block span {
	background: radial-gradient(93.93% 93.94% at 6.07% 48.57%, #2e1b0d 0%, #ce0bff 100%), linear-gradient(91deg, #4424e4 -31.56%, #1c7d4a 125.13%);
}
.game_heading_wrapper {
	display: flex;
}

.game_heading_wrapper .game_heading_left {
	margin-right: 8px;
	flex-grow: 1;
}

.game_heading_wrapper .game_heading_right {
	display: flex;
	flex-grow: 1;
	justify-content: end;
}
.game_heading_wrapper .game_heading_right .input_item {
	display: flex;
	border: 1px solid var(--background);
	height: max-content;
	border-radius: 8px;
}
.game_heading_wrapper .game_heading_right .input_item svg {
	color: #fff;
	margin-top: auto;
	margin-bottom: auto;
	margin-left: 6px;
}
.game_heading_wrapper .game_heading_right .input_item input {
	border: unset;
	outline: unset;
	padding-left: 4px;
	min-width: 200px;
	height: 35px;
}

.game_heading_wrapper .game_heading_right > div {
	margin-right: 8px;
}
.game_heading_wrapper .game_heading_right .filter_icon button {
	padding: 8px;
	border-radius: 8px;
	background: #fff;
	color: #000;
	font-family: "Clash Display";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
}
.game_heading_wrapper .game_heading_right .filter_icon button svg {
	margin-left: 4px;
	color: #fff;
}
.game_heading_wrapper .game_heading_right .add_game button {
	padding: 8px;
	border-radius: 8px;
	background: var(--background);
	color: #ffff;
	font-family: "Clash Display";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
}
.game_heading_wrapper .game_heading_right .add_game button svg {
	margin-left: 4px;
	color: #fff;
}

.admin_table .profile_icon {
	display: flex;
}
.admin_table .profile_icon .img {
	margin-right: 8px;
	margin-top: auto;
	margin-bottom: auto;
}
.admin_table .profile_icon .name {
	margin-top: auto;
	margin-bottom: auto;
}
.admin_table .profile_icon .img img {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	border: 1px solid #fff;
	text-align: center;
}
.admin_table .action_icon svg {
	cursor: pointer;
	margin-right: 8px;
}

.remove_box h2 {
	margin-top: 16px;
	margin-bottom: 16px;
	text-align: center;
}

.remove_box .button_item {
	margin: auto;
	max-width: 390px;
}
.remove_box .button_item button {
	display: block;
	height: 50px;
	width: 100%;
	margin-bottom: 16px;
	font-size: 18px;
	font-family: "Clash Display";
}
.remove_box button.delete {
	margin-right: 8px;
}
.remove_box button.cancel {
	background: linear-gradient(97deg, #dc3535 0%, #ff50c0 65.85%);
}
.remove_box button.add {
	background: linear-gradient(97deg, #d0a10a 0%, #ff6750 65.85%);
}

.edit_box {
	padding: 16px;
}

.edit_box .input_items .input_item {
	margin-bottom: 16px;
}

.edit_box .remove_box .button_item,
.view_data .remove_box .button_item {
	margin: unset;
}

.password_icon_wrapper {
	position: relative;
}
.password_icon_wrapper svg {
	position: absolute;
	right: 10px;
	color: #fff;
	top: 0px;
	bottom: 0px;
	margin: auto;
	cursor: pointer;
}

.table .category_item {
	display: flex;
	flex-wrap: wrap;
}

table .category_item span {
	min-width: 50px;
	text-align: center;
	margin-bottom: 8px;
	padding: 4px 8px;
	margin-right: 4px;
	border-radius: 8px;
	background: radial-gradient(93.93% 93.94% at 6.07% 48.57%, #3c3c3c 0%, #ff1616 100%), linear-gradient(91deg, #181622 -31.56%, #302283 125.13%);
	cursor: pointer;
}

.input_items.category_input input {
	border-color: #fff;
	outline: 0px;
}
.input_items .dynamic_game_label {
	text-transform: capitalize;
}

.dynamic_admin_img {
	max-width: 300px;
}

.dynamic_store_wrapper {
	border: 1px solid red;
	margin-bottom: 16px;
	border-radius: 12px;
	background: var(--background_sec);
	border: solid 2px transparent;
	background-origin: border-box;
	background-clip: content-box, border-box;
	background-image: linear-gradient(#08090b, #08090b), linear-gradient(97deg, #f9c3ff 0%, #8650ff 65.85%);
	margin-bottom: 16px;
}

.dynamic_store.item {
	padding: 16px;
}

.category_remove_button p {
	text-decoration: underline;
	margin-bottom: 8px;
	margin-top: 4px;
	background: linear-gradient(97deg, #f9c3ff 0%, #8650ff 65.85%);
	border-radius: 8px;
	padding: 0px 4px;
	max-width: 70px;
	text-align: center;
	cursor: pointer;
	margin-left: auto;
}
.admin_news_data h1 {
	font-size: 24px;
	margin-bottom: 16px;
	margin-top: 16px;
}

.submit_game_popup .game_input {
	position: unset;
	flex-grow: unset;
	padding: unset;
	max-width: unset;
	width: unset;
}

/* Tooltip css start */

.custom_tooltip {
	position: relative;
	display: inline-block;
}

.custom_tooltip .tooltiptext {
	visibility: hidden;
	background: #fff;
	color: #000;

	width: 50px;
	margin-left: -8px;
	font-weight: bold;
	text-align: center;
	border-radius: 6px;
	padding: 5px 0;
	position: absolute;
	z-index: 1;
	bottom: 150%;
	/* left: 50%;
	margin-left: -60px; */
}

.custom_tooltip .tooltiptext::after {
	content: "";
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: #fff transparent transparent transparent;
}

.custom_tooltip:hover .tooltiptext {
	visibility: visible;
}

/* Tooltip css end */

@media screen and (max-width: 991px) {
	.admin_table .action_icon svg {
		margin-bottom: 4px;
	}
	.admin_table table th {
		vertical-align: text-bottom;
	}
	.admin_table table th,
	.admin_table table td {
		min-width: auto;
	}
}
@media screen and (max-width: 767px) {
	.admin_container .game_heading_wrapper {
		display: block;
	}
	.admin_container .game_heading_wrapper .game_heading_right {
		margin-bottom: 16px;
		justify-content: unset;
	}
}
@media screen and (max-width: 450px) {
	.admin_container .game_heading_wrapper .game_heading_right {
		flex-wrap: wrap;
	}
	.admin_container .game_heading_wrapper .game_heading_right .input_item {
		width: 100%;
		margin-bottom: 16px;
	}
}
