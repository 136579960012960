.se-toolbar.sun-editor-common.se-toolbar-sticky {
	position: unset !important;
}
.se-toolbar-sticky-dummy,
button[data-command="mirror"],
button[data-command="percent"],
button[data-command="rotate"],
button[data-command="caption"] {
	display: none !important;
}
.se-controller.se-controller-resizing .se-btn-group,
.se-controller.se-controller-resizing .se-btn-group {
	display: inline-block !important;
}

.sun-editor-editable,
.sun-editor .se-toolbar {
	background: #000;
	color: #fff;
}
.sun-editor span,
.sun-editor svg {
	color: #fff !important;
}
.sun-editor .se-resizing-bar {
	display: none;
}
.sun-editor .se-btn:enabled:focus,
.sun-editor .se-btn:enabled:hover {
	background-color: rgba(127, 127, 127, 0.6);
	border-color: rgba(127, 127, 127, 0.6);
}
.sun-editor .se-wrapper .se-wrapper-wysiwyg {
	font-size: 16px;
	font-family: "Clash Display";
}
