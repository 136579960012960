.development_section {
	background-color: rgba(1, 1, 1, 0.5);
	height: 100vh;
}
.development_col {
	max-width: 400px;
	margin: 30px auto;
	background-color: #000;
	padding: 25px;
	border-radius: 5px;
}
.development_col .development_form {
	border: 1px solid var(--background);
	padding: 16px;
	border-radius: 8px;
}
.development_col .development_form p {
	text-align: center;
}

.development_col .development_form input {
	margin-top: 10px;
	display: block;
	width: 100%;
	box-sizing: border-box;
	padding: 8px 5px;
	border: 1px solid var(--btn_color);
	border-radius: 5px;
}
.development_col .development_form button {
	display: block;
	padding: 8px 5px;
	outline: none;
	background-color: var(--background);
	width: 100%;
	color: #fff;
	border-radius: 8px;
	margin-top: 10px;
	border: 1px solid var(--background);
}
